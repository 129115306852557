/* List CSS - START */
@import "../../../constants/variables.scss";

.project-list-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &.three {
    .cards {
      width: 30%;
      margin-bottom: 5%;
      background-color: $theme-white;
      &:not(:nth-child(3n)) {
        margin-right: 5%;
      }
    }
  }
  &.four {
    .cards {
      width: 23.5%;
      margin-bottom: 2%;
      background-color: $theme-white;
      &:not(:nth-child(4n)) {
        margin-right: 2%;
      }
    }
  }
  &.five {
    .cards {
      width: 19%;
      margin-bottom: 2%;
      background-color: $theme-white;
      &:not(:nth-child(5n)) {
        margin-right: 1.25%;
      }
    }
  }
  .no-projects-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 6rem;
    font-size: 1.125rem;
    font-family: $font-mulish;
  }
}

@media only screen and (max-width: 900px) {
  .project-list-wrapper {
    &.three {
      .cards {
        width: 48%;
        &:not(:nth-child(3n)) {
          margin-right: 0;
        }
        &:not(:nth-child(2n)) {
          margin-right: 4%;
        }
      }
    }
    &.four {
      .cards {
        width: 48%;
        margin-bottom: 4%;
        &:not(:nth-child(4n)) {
          margin-right: 0;
        }
        &:not(:nth-child(2n)) {
          margin-right: 4%;
        }
      }
    }
    &.five {
      .cards {
        width: 48%;
        margin-bottom: 4%;
        &:not(:nth-child(5n)) {
          margin-right: 0;
        }
        &:not(:nth-child(2n)) {
          margin-right: 4%;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .project-list-wrapper {
    &.three {
      .cards {
        width: 100%;
        &:not(:nth-child(3n)) {
          margin-right: 0;
        }
        &:not(:nth-child(2n)) {
          margin-right: 0%;
        }
      }
    }
    &.four {
      .cards {
        width: 100%;
        margin-bottom: 4%;
        &:not(:nth-child(4n)) {
          margin-right: 0;
        }
        &:not(:nth-child(2n)) {
          margin-right: 0%;
        }
      }
    }
    &.four {
      .cards {
        width: 100%;
        margin-bottom: 4%;
        &:not(:nth-child(5n)) {
          margin-right: 0;
        }
        &:not(:nth-child(2n)) {
          margin-right: 0%;
        }
      }
    }
  }
}
/* List CSS - END */
