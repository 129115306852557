.banner-wrapper {
  position: relative;
  height: 100%;
  .image {
    height: 100%;
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
