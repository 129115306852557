@import "../../../constants/variables.scss";
.home-wrapper {
  .main-carousel {
    height: 100vh;
    .slider-wrapper {
      height: 100%;
      .swiper-container {
        height: 100%;
        .swiper-scrollbar {
          visibility: hidden;
          bottom: 0;
          height: 0;
        }
        .swiper-pagination.swiper-pagination-bullets {
          visibility: visible;
        }
        .swiper-wrapper {
          max-height: 320px;
          .swiper-slide {
            margin: 0;
            cursor: pointer;
            .image {
              position: relative;
              width: 100%;
              height: 100vh;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
              .carousel-text {
                width: 100%;
                height: 100%;
                color: $theme-white;
                text-align: center;
                font-family: $font-fahkwang;
                padding: 3rem 0;
                background-color: rgba(19, 33, 61, 0.4);
                text-transform: capitalize;
                .primary-text {
                  font-size: $extraLarge;
                  position: absolute;

                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
                .secondary-text {
                  font-size: $regular;
                  letter-spacing: 1px;
                }
              }
            }
          }
        }
      }
    }
  }
  .about {
    background-color: $theme-blue;
    color: $theme-white;
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 6rem;
    .left-section {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 40%;
      margin-right: 2rem;
      .title {
        font-size: $regular17;
        font-family: $font-mulish;
        font-weight: 800;
        margin-bottom: 3rem;
      }
      .subtitle {
        font-size: 3rem;
        font-family: $font-fahkwang;
        margin-bottom: 2rem;
      }
      .description {
        font-size: $regular17;
        font-family: $font-mulish;
        margin-bottom: 2rem;
      }
      .image-text-wrapper {
        margin-top: 6rem;
      }
    }
    .image-wrapper {
      width: 40%;
      .image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
  .view-work {
    margin-bottom: 3rem;
    color: $theme-black;
    width: 100%;
    .action-link {
      padding: 0 6rem;
    }
    .scroll-carousel-wrapper {
      margin-top: 4rem;
      margin-left: 25%;
      width: 75%;
      height: 350px;
      .slider-wrapper {
        height: 100%;
        .swiper-container {
          height: 100%;
          .swiper-scrollbar {
            visibility: visible;
            bottom: 0;
            height: 3px;
          }
          .swiper-wrapper {
            max-height: 320px;
            .swiper-slide {
              margin: 0;
              max-height: 320px;
              border: 1px solid $orange;
              border-top-width: 2px;
              border-bottom-width: 2px;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 5;
              overflow: hidden;
              cursor: pointer;
              &:hover {
                img {
                  transition: transform 3s;
                  -webkit-transition: transform 3s;
                  transform: translate3d(0px, 0px, 0px) scale(1.15);
                }
              }
              img {
                transition: transform 3s;
                -webkit-transition: transform 3s;
              }
              .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background-color: rgba(0, 0, 0, 0.5);
                color: $theme-white;
                .overlay-text {
                  position: absolute;
                  bottom: 15px;
                  left: 15px;
                  font-family: $font-mulish;
                  font-weight: 600;
                  letter-spacing: 1px;
                }
              }
              &:first-child {
                border-left-width: 2px;
              }
              &:last-child {
                border-right-width: 2px;
              }
            }
          }
        }
      }
    }
  }
  .banner-section {
    height: 50vh;
    margin-top: 6rem;
    .banner-content {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba($color: $theme-blue, $alpha: 0.7);
      width: 100%;
      height: 100%;
      z-index: 2;
      .title {
        color: $theme-white;
        font-size: 2rem;
        font-family: $font-fahkwang;
        // font-weight: 800;
        padding: 1rem 0;
      }
      .client-logos {
        display: flex;
        flex-wrap: wrap;
        padding: 0 6rem;
        width: 100%;
        .client-logo-wrapper {
          width: 12%;
          &:not(:nth-child(5n)) {
            margin-right: 10%;
          }
          img {
            height: auto;
          }
        }
      }
    }
    .banner-wrapper {
      img {
        height: 100%;
      }
    }
  }
  .slider-wrapper {
    .swiper-container {
      .swiper-scrollbar {
        visibility: hidden;
      }
      .swiper-wrapper {
        align-items: center;
        .swiper-slide {
          width: 50%;
          margin: 0 5%;
          .slider-image-wrapper {
            &:hover {
              .custom-slider-image {
                transition: transform 3s;
                -webkit-transition: transform 3s;
                transform: translate3d(0px, 0px, 0px) scale(1.15);
              }
            }
          }
          .slider-image-wrapper {
            height: 300px;
            max-height: 300px;
            overflow: hidden;
            .custom-slider-image {
              height: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              transition: transform 3s;
              -webkit-transition: transform 3s;
            }
          }
          .featured-project-details {
            visibility: hidden;
          }
          &.swiper-slide-active {
            .slider-image-wrapper {
              height: 350px;
              max-height: 350px;
            }
            .featured-project-details {
              visibility: visible;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1rem 0;
              .project-title {
                font-family: $font-mulish;
                font-weight: 700;
                font-size: $regular17;
              }
              .image-text-wrapper {
                height: 30px;
                .img-wrapper {
                  width: 30px;
                  height: 100%;
                }
                a {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .footer-wrapper {
    margin-top: 6rem;
  }
}

@media only screen and (min-width: $tab-min-width) and (max-width: $tab-max-width) {
  .home-wrapper {
    .view-work {
      .scroll-carousel-wrapper {
        height: 255px;
        .slider-wrapper {
          .swiper-container {
            .swiper-wrapper {
              max-height: 225px;
              .swiper-slide {
                max-height: 225px;
              }
            }
          }
        }
      }
    }
    .about {
      .left-section {
        .title{
          font-size: $input;
          font-weight: 800;
        }
      }
    }
    .slider-wrapper {
      .swiper-container {
        .swiper-wrapper {
          .swiper-slide {
            .slider-image-wrapper {
              height: 225px;
              max-height: 225px;
            }
            &.swiper-slide-active {
              .slider-image-wrapper {
                height: 275px;
                max-height: 275px;
              }
              .featured-project-details {
                .project-title {
                  font-family: $font-mulish;
                  font-weight: 700;
                  font-size: 1.0625rem;
                }
                .image-text-wrapper {
                  height: 20px;
                  .img-wrapper {
                    width: 20px;
                    height: 100%;
                    right: 5px;
                    left: initial;
                  }
                  a {
                    font-size: 1.0625rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .home-wrapper {
    .main-carousel {
      .slider-wrapper {
        .swiper-container {
          .swiper-wrapper {
            .swiper-slide {
              .image {
                .carousel-text {
                  .primary-text {
                    font-size: 6rem;
                  }
                  .secondary-text {
                    margin-top: 1rem;
                    font-size: $h4;
                  }
                }
              }
            }
          }
        }
      }
    }
    .about {
      flex-direction: column;
      text-align: left;
      padding: 4rem;
      .left-section {
        width: 100%;
        margin-right: 0;
        margin-bottom: 6rem;
        .title {
          font-size: $input;
          font-weight: 800;
        }
        .description {
          font-size: $input;
        }
      }
      .image-wrapper {
        width: 100%;
        height: 35vh;
      }
    }
    .view-work {
      margin-bottom: 3rem;
      color: $theme-black;
      width: 100%;
      .action-link {
        padding: 0 4rem;
      }
      .scroll-carousel-wrapper {
        margin-top: 4rem;
        margin-left: 25%;
        width: 75%;
        height: 350px;
        .slider-wrapper {
          height: 100%;
          .swiper-container {
            height: 100%;
            .swiper-scrollbar {
              visibility: visible;
              bottom: 0;
              height: 3px;
            }
            .swiper-wrapper {
              max-height: 320px;
              .swiper-slide {
                margin: 0;
                max-height: 320px;
                border: 1px solid $orange;
                border-top-width: 2px;
                border-bottom-width: 2px;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 5;
                overflow: hidden;
                cursor: pointer;
                &:hover {
                  img {
                    transition: transform 3s;
                    -webkit-transition: transform 3s;
                    transform: translate3d(0px, 0px, 0px) scale(1.15);
                  }
                }
                img {
                  transition: transform 3s;
                  -webkit-transition: transform 3s;
                }
                .overlay {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: $theme-white;
                  .overlay-text {
                    position: absolute;
                    bottom: 15px;
                    left: 15px;
                    font-family: $font-mulish;
                    font-weight: 600;
                    font-size: $large-mobile;
                    letter-spacing: 1px;
                  }
                }
                &:first-child {
                  border-left-width: 2px;
                }
                &:last-child {
                  border-right-width: 2px;
                }
              }
            }
          }
        }
      }
    }
    .banner-section {
      height: 40vh;
      .banner-content {
        padding: 6rem;
        .title {
          padding: 0;
          // font-weight: 800;
        }
        .client-logos {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          width: 100%;
          .client-logo-wrapper {
            width: 25%;
            margin: 0 12.5%;
            &:not(:nth-child(5n)) {
              margin-right: 12.5%;
            }
            &:nth-of-type(n + 5) {
              display: none;
            }
            img {
              height: auto;
            }
          }
        }
      }
      .banner-wrapper {
        img {
          height: 100%;
        }
      }
    }
    .slider-section {
      padding: 0 4rem;
      .slider-wrapper {
        .swiper-container {
          .swiper-scrollbar {
            visibility: hidden;
          }
          .swiper-wrapper {
            align-items: center;
            .swiper-slide {
              width: 50%;
              margin: 0;
              .featured-project-details {
                visibility: hidden;
                height: calc(2rem + 1.0625rem);
              }
              &.swiper-slide-active {
                .slider-image-wrapper {
                  height: 300px;
                  max-height: 300px;
                }
                .featured-project-details {
                  visibility: visible;
                  display: flex;
                  justify-content: space-between;
                  padding: 1rem 0;
                  .project-title {
                    font-family: $font-mulish;
                    font-weight: 700;
                    font-size: 1.0625rem;
                  }
                  .image-text-wrapper {
                    .img-wrapper {
                      width: 20px;
                      height: 20px;
                      right: 5px;
                      left: initial;
                    }
                    a {
                      font-size: 1.0625rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .footer-wrapper {
      margin-top: 6rem;
    }
  }
}
