/* FONTS */
@import url("https://fonts.googleapis.com/css2?family=Fahkwang:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600&display=swap");
@import "./constants/variables.scss";
/* Common Styles */
* {
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a {
  color: currentColor;
  text-decoration: none;
}
.bold-text {
  font-weight: bold;
}
.btn-large {
  padding: 1.5rem 4rem;
  font-size: 1.5rem;
}
.btn-white {
  border: 1px solid $lightblue;
  color: $theme-blue;
  &:hover {
    background-color: $theme-blue;
    color: $theme-white;
  }
}

@media only screen and (min-width: $web-min-width) {
  html {
    font-size: 16px;
  }
}

@media only screen and (min-width: $tab-min-width) and (max-width: $tab-max-width) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: $mobile-max-width) {
  html {
    font-size: 10px;
  }
}
