/* Responsive Dimensions */

$mobile-max-width: 500px;
$tab-min-width: 500px;
$tab-max-width: 900px;
$web-min-width: 900px;

/* Color Variables - START */

$theme-black: #000;
$theme-blue: #13213d;
$theme-white: #ffffff;
$orange: #f06522;
$lightblue: #b0c2dd;
$brown: #ae795a;
$beige: #f1dccf;
$light-grey: #b8b8b8;

/* Color Variables - END */

/* Font Family Variables - START */

$font-fahkwang: 'Fahkwang', sans-serif;
$font-mulish: 'Mulish', sans-serif;

/* Font Family Variables - END */

/* Font Size Variables - START */

$extraLarge: 5rem;
$huge: 4rem;
$h1: 2.3rem;
$h2: 2.8125rem;
$h3: 2.1875rem;
$h4: 1.75rem;
$input: 1.5rem;
$large: 1.375rem;
$large-mobile: 1.3rem;
$h5: 1.25rem;
$h6: 1.1875rem;
$regular17: 1.125rem;
$regular: 1rem;
$regular15: 0.9375rem;
$medium: 0.875rem;
$smallMedium: 0.8125rem;
$small: 0.75rem;
$smaller: 0.625rem;
$tiny: 0.5rem;
$minute: 0.375rem;
$space_large: 0.4375rem;

/* Font Size Variables - END */
