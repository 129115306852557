@import "../../constants/variables.scss";

.slider-wrapper {
  .swiper-container {
    .swiper-pagination.swiper-pagination-bullets {
      visibility: hidden;
      .swiper-pagination-bullet {
        background-color: $theme-white;
        border: 1px solid $theme-blue;
        &.swiper-pagination-bullet-active {
          background-color: $theme-blue;
          border: 1px solid $theme-white;
        }
      }
    }
    .swiper-slide {
      .slider-image-wrapper {
        height: 280px;
        max-height: 280px;
        img.image {
          height: 100%;
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 40px;
      height: 40px;
      border: 1px solid $theme-blue;
      background-color: $theme-white;
      border-radius: 50%;
      &::after {
        font-size: $h5;
        color: $theme-blue;
      }
      &:hover {
        background-color: $theme-blue;
        &::after {
          color: $theme-white;
        }
      }
    }
  }
}

@media only screen and (max-width: $tab-max-width) {
  .slider-wrapper {
    .swiper-container {
      .swiper-pagination.swiper-pagination-bullets {
        .swiper-pagination-bullet {
          &.swiper-pagination-bullet-active {
            background-color: $theme-white;
            border: 1px solid $theme-blue;
          }
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        &:hover {
          background-color: $theme-white;
          &::after {
            color: $theme-blue;
          }
        }
      }
    }
  }
}